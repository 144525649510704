<template>
  <div class="work-contact-send" style="padding: 10px;background: #F2F4F8">
    <div class="block">
      <van-cell required size="large" title="向我的客户群发消息"/>
      <div class="content">
        <div class="tips">
          <p>客户群发每个客户每个月仅可收到30条客户群发消息</p>
          <p>若管理员在后台为客户创建客户群发也会占用群发机会</p>
          <p>个人群发每天可给客户发送一条群发消息</p>
        </div>
        <van-cell @click="userGet" required is-link title="选择群发账号" value="请选择">
          {{userName || '请选择'}}
          <selectEmployee v-show="false" @change="getCount" ref="groups"/>
        </van-cell>
      </div>
    </div>

    <div class="block">
      <div class="content">
        <van-cell required :border="false" title="选择日期"/>
        <div class="form" style="margin: 0 12px;margin-top: -10px">
          <div class="input">
            <div class="date-input">
              <div class="box">
                <a-date-picker placeholder="请选择开始日期" @change="countQuantity" v-model="time[0]"/>
              </div>
              <div class="box">
                <a-date-picker placeholder="请选择结束日期" @change="countQuantity" v-model="time[1]"/>
              </div>
            </div>
          </div>
        </div>
        <van-cell required @click="tagsGet" is-link :border="false" title="按标签筛选">
          <selectTag @change="tagsData" v-show="false" ref="tags"/>
          {{tags || '请选择'}}
        </van-cell>
        <van-cell required @click="excludeGet" is-link :border="false" title="排除客户">
          <selectTag @change="excludeData" v-show="false" ref="excludeTags"/>
          {{exclude || '请选择'}}
        </van-cell>
        <van-cell :border="false" title="该消息预计送达人数">
          <a @click="getCount" style="font-size: 12px">
            {{ count === null ? '查看' : count }}
          </a>
        </van-cell>
      </div>
    </div>

    <div class="block">
      <div class="content">
        <div class="form">
          <div class="input">
            <div class="input-block-box">
              <textarea
                  class="textarea"
                  contenteditable="true"
                  v-model="msgText"
                  placeholder="请输入群发内容"
              />
              <div class="msg-list" v-if="msgList.length > 0">
                <div class="item" v-for="(v,i) in msgList">
                  <a-icon type="minus-circle" @click="msgList.splice(i,1)"/>
                  <span>【{{ map[v.msgType] }}】</span>
                  ：
                  <span v-if="v.msgType === 'image'"><img :src="v.fullPath"></span>
                  <span v-else>{{ v.title }}</span>
                </div>
              </div>
              <div class="add" @click="$refs.annexPopup.show()">
                <a style="color: #000">
                  <img src="../../assets/add-file.svg" style="margin-right: 5px;width: 25px;" alt="">
                  添加附件
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button">
      <a-button style="margin-right: 16px;" @click="$router.push('/')">取消</a-button>
      <a-button type="primary" @click="addClick">创建群发</a-button>
    </div>

    <annexPopup ref="annexPopup" @change="e => msgList.push(e)"/>
  </div>
</template>

<script>
import selectTag from "../../components/select/tag/select";
import selectEmployee from "../../components/select/employee/select";
import annexPopup from "../../components/annex/popup";
import moment from 'moment';
import {getSendUserCount, add} from "../../api/contactMessageBatchSend";

export default {
  data() {
    return {
      time: [],
      msgText: '',
      msgList: [],
      map: {
        image: '图片',
        link: '链接',
        miniprogram: '小程序'
      },
      count: null,
      userName: '',
      tags: '',
      exclude: ''
    }
  },
  methods: {
    get() {
      const employeeIds = this.$refs.groups.get().map(v => {
        return v.id;
      });

      const tags = this.$refs.tags.get().map(v => {
        return v.id;
      });
      const removeTags = this.$refs.excludeTags.get().map(v => {
        return v.id;
      });

      const addTimeStart = this.time[0] ? moment(this.time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      const addTimeEnd = this.time[1] ? moment(this.time[1]).format('YYYY-MM-DD HH:mm:ss') : '';

      return {
        employeeIds,
        tags,
        removeTags,
        addTimeStart,
        addTimeEnd
      }
    },

    addClick() {
      const data = this.get();
      if (!data.employeeIds.length) {
        this.$message.error('群发账号未选择');

        return false;
      }

      if (!this.msgText && this.msgList.length === 0) {
        this.$message.error('群发内容未填写');

        return false;
      }

      let contentParams;
      if (this.msgText.length !== 0) {
        contentParams = JSON.stringify([
          {
            msgType: 'text',
            content: this.msgText
          },
          ...this.msgList
        ])
      } else {
        contentParams = JSON.stringify([
          ...this.msgList
        ])
      }

      const params = {
        employeeIds: data.employeeIds,
        textContent: this.msgText,
        content: contentParams,
        filterParams: JSON.stringify({
          addTimeStart: data.addTimeStart,
          addTimeEnd: data.addTimeEnd,
          rooms: [],
          tags: data.tags,
          removeTags: data.removeTags
        })
      }
      const appidEdit = JSON.parse(params.content)
      appidEdit.forEach(item=>{
        if(item.appid){
          item.path = item.pic.path
        }
      })
      params.content = JSON.stringify(appidEdit)
      add(params).then(_ => {
        this.$message.success('添加成功');
        this.$router.back();
      })
    },

    getCount() {
      const data = this.get();
      this.userName = String(this.$refs.groups.get().map(v => {
        return v.name;
      }))
      if (!data.employeeIds.length) {
        this.$message.error('群发账号未选择');
        this.count = null
        return false;
      }

      const params = {
        ...data,
        tagsType: 1,
      }

      getSendUserCount(params).then(res => {
        this.count = res.data[0];
      })
    },

    countQuantity(){
      const data = this.get();
      if (!data.employeeIds.length) {
        return false;
      }
      const params = {
        ...data,
        tagsType: 1,
      }
      getSendUserCount(params).then(res => {
        this.count = res.data[0];
      })
    },

    userGet() {
      this.$refs.groups.show()
    },
    tagsData(e){
      this.tags = String(e.map(v => {
        return v.name;
      }))
      this.countQuantity()
    },
    excludeData(e){
      this.exclude = String(e.map(v => {
        return v.name;
      }))
      this.countQuantity()
    },
    excludeGet(){
      this.$refs.excludeTags.show()
    },
    tagsGet(){
      this.$refs.tags.show()
    }

  },
  components: {
    selectTag,
    selectEmployee,
    annexPopup
  }
}
</script>

<style lang="less" scoped>
.date-input {
  display: flex;
  align-items: center;

  .box {
    margin-right: 6px;
    margin-bottom: 6px;
  }
}
.count{
  font-size: 14px;
}
.block {
  margin-bottom: 16px;
  background: #fff;
  background: #fff;
  padding: 3px 5px;
  border-radius: 7px;
  .title {
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 13px;
      background: #1890ff;
      margin-right: 6px;
    }
  }

  .tips {
    width: 96%;
    margin: 0 auto;
    font-size: 12px;
    line-height: 24px;
    background: #f7fbff;
    border: 1px solid #cce9ff;
    border-radius: 3px;
    color: #000000a6;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 8px;

    p {
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;

      &:before {
        width: 3px;
        height: 3px;
        background: #95cbf3;
        content: "";
        display: inline-block;
        margin-left: 9px;
        margin-right: 4px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.form {

  .label {
    font-size: 15px;
    margin-bottom: 6px;
  }

  .text {
    font-size: 12px;
    color: #00000073;
    margin-left: 6px;
  }
}

.input-block-box {
  max-width: 370px;

  .textarea {
    width: 100%;
    height: 178px;
    overflow: auto;
    outline: none;
    padding: 8px 16px;
    font-size: 13px;
    background: none;
    border: none;
  }

  .add {
    padding: 8px 16px;
    border-top: 1px solid #eee;
    font-size: 13px;
  }
}

.msg-list {
  border-top: 1px solid #eee;
  .item {
    font-size: 14px;
    padding: 8px 16px;
  }
  i {
    cursor: pointer;
  }
  img {
    width: 25px;
    height: 25px;
  }
}
</style>
